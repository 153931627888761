
import React, { useState, } from "react";
import "./customer-support.scss";
import * as DateUtil from "../../../utils/date";
import Table from '../../elements/table/table';
import CSModal from './cs-modal';

const CustomerSupport = () => {

  const [showValue,setShowValue] = useState(null);
  const [showModal,setShowModal] = useState(false);
  
  const sideItems = [
    {display: "All", conditions:[]},
    {display: "Open", conditions:[{
      column:"status", table:"tickets", action: "=",value: "open",
    }]},
    {display: "Closed", conditions:[{
      column:"status", table:"tickets", action: "=",value: "closed",
    }]},
  ];

  const columns = [
    {table: "tickets",value: "id",hide: true,},
    {table: "tickets",value: "uid",hide:true,},
    {table: "tickets",value: "userId",},
    {table: "tickets",value: "status",},
    {table: "tickets",value: "type",},
    {table: "tickets",value: "staffUserId",},
    {table: "tickets",value: "needsReply",},
    {table: "tickets",value: "createdDate",
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
    {table: "tickets",value: "updatedDate", 
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
  ]
  const joinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"outputs",rightTable:"addresses",leftColumn:"address",rightColumn:"address",},
  ]

  return (
    <div className="customer-support-comp">
      <div className="customer-support-comp-header">
        <h3>Customer Support</h3>
      </div>
      <Table 
        database={"Main"}
        displayName="CustomerSupport"
        name="CustomerSupportTable"
        sideItems={sideItems}
        sideItemActiveClickFunc={(item) => {
          // console.log("item",item);
        }}
        joinOptions={joinOptions}
        rowOnClickFunc={(value) => {
          setShowValue(value.ticketsUid);
          setShowModal(true);
        }}
        columns={columns}
        limit={250}
        />

      <CSModal 
        show={showModal} 
        handleClose={() => {setShowModal(false)}}
        ticketId={showValue}
        />

    </div>
  );
};

export default CustomerSupport;
