import React, { useState, } from "react";
import "./issues.scss";
import * as DateUtil from "../../../utils/date";
import Table from '../../elements/table/table';
import IssueModal from "./issue-modal";
import useUserState from '../../../store/user';

const Issues = () => {

  const {user} = useUserState();

  const [showIssueModal, setShowIssueModal] = useState(false);
  const [issuePaymentUid, setIssuePaymentUid] = useState(null);

  const sideItems = [
    {display:"Asigned to me & Unresolved",conditions:[
      {table:"issues", column:"resolved",value:0,action:"="},
      {table:"issues", column:"staffUserId",value:user.id,action:"="},
    ]},
    {display:"Escalated & Unresolved",conditions:[
      {table:"issues", column:"resolved",value:0,action:"="},
      {table:"issues", column:"escalated",value:1,action:"="},
    ]},
    {display:"Asigned to me",conditions:[
      {table:"issues", column:"staffUserId",value:user.id,action:"="},
    ]},
    {display:"Unresolved",conditions:[
      {table:"issues", column:"resolved",value:0,action:"="},
    ]},
    {display:"Escalated",conditions:[
      {table:"issues", column:"escalated",value:1,action:"="},
    ]},
    {display:"Resolved",conditions:[
      {table:"issues", column:"resolved",value:1,action:"="},
    ]},
  ];

  const columns = [
    {table: "issues",value: "paymentUid", index:true, },
    {table: "issues",value: "userId", },
    {table: "stores",value: "name", },
    {table: "issues",value: "staffUserId", index:true, },
    {table: "issues",value: "extend", },
    {table: "issues",value: "escalated", index:true,  },
    {table: "issues",value: "createdDate", index:true, defaultOrder:"DESC",
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
  ]
  const joinOptions = [
    {joinType:"LEFT JOIN",leftTable:"issues",rightTable:"stores",leftColumn:"storeId",rightColumn:"id",},
  ]

  return (
    <div className="issues-comp">
      <div className="issues-comp-header">
        <h3>Merchant Issues</h3>
      </div>
      <Table 
        database={"Merchant"}
        displayName="Issues"
        name="IssuesTable"
        sideItems={sideItems}
        sideItemActiveClickFunc={(item) => {
          // console.log("item",item);
        }}
        joinOptions={joinOptions}
        rowOnClickFunc={(value) => {
          setIssuePaymentUid("payment_"+value.issuesPaymentUid);
          setShowIssueModal(true);
        }}
        columns={columns}
        limit={250}
      />

      <IssueModal 
        show={showIssueModal} 
        side={"host"}
        paymentUid={issuePaymentUid}
        handleClose={() => {
          setShowIssueModal(false);
          setIssuePaymentUid(null)
        }} 
        />
    </div>
  );
};

export default Issues;
