import React from 'react';
import './withdrawals.scss';

const Withdrawals = () => {

  return (
    <div className="withdrawals">
      <h1>withdrawals</h1>
    </div>
  );

};

export default Withdrawals;