import React,{useEffect, useState, useRef} from 'react';
import './table.scss'
import * as StringUtil from '../../../utils/string';
import Loading from '../../common/loading/loading';
import Button from '../../elements/button/button';
import usePersistState from '../../../utils/use-persist-state';
import {clone} from '../../../utils/clone';
import Modal from '../../common/modal/modal';
import { request, unSubRequest } from '../../../utils/request';
import { toast } from 'react-toastify';
import Pagination from '../../common/pagination/pagination';
import useDebouncedEffect from '../../../utils/use-debounced-effect';
import TypeEpochInput from './type-epoch-input';

const Table = ({
        database,
        displayName,
        name,
        sideItems,
        sideItemActiveClickFunc,
        joinOptions,
        columns,
        searchEndpoint,
        rowOnClickFunc,
        limit,
        disableAdvancedSearch,
        onlyAdvancedSearch,
        refreshInc,
        buttons,
        newButtonText,
        newButtonOnClickFunc,
        useSearchBar,
        load,
      }) => {

  // console.log("database",database);
  // console.log("displayName",displayName);
  // console.log("name",name);
  // console.log("sideItems",sideItems);
  // console.log("joinOptions",joinOptions);
  // console.log("columns",columns);
  // console.log("searchEndpoint",searchEndpoint);

  searchEndpoint = (searchEndpoint)?searchEndpoint:"/search";
  limit = (limit)?limit:1000;
  joinOptions = (joinOptions)?joinOptions:null;
  load = (load !== undefined && load === false)?false:true;
  useSearchBar = useSearchBar !== undefined?useSearchBar:true;

  let defaultOrderBy = null;
  let defaultOrderDir = null;
  for (let i = 0; i < columns.length; i++) {
    const column = columns[i];
    if(column.defaultOrder){
      defaultOrderBy = column;
      defaultOrderDir = (column.defaultOrderDir === "ASC")?"ASC":"DESC";
    }
  }

  let initLeftActiveItem = (sideItems.length && sideItems[0].conditions)?sideItems[0].conditions:[];
  let initLeftActiveItemKey = 0;
  for (let i = 0; i < sideItems.length; i++) {
    const sideItem = sideItems[i];
    if(sideItem.defaultActive){
      initLeftActiveItemKey = i;
      initLeftActiveItem = sideItem.conditions;
      break;
    }
  }

  const searchActions = ["LIKE","=",">","<",">=","<="];

  const [loading,setLoading] = useState(false);
  const [show,setShow] = useState(false);
  const [leftActiveItem,setLeftActiveItem] = useState(initLeftActiveItem);
  const [leftActiveItemKey,setLeftActiveItemKey] = useState(initLeftActiveItemKey);
  const [searchConditions,setSearchConditions] = usePersistState([],name+"Search","array");
  const [results,setResults] = useState([]);
  const [orderBy,setOrderBy] = useState(defaultOrderBy);
  const [orderDir,setOrderDir] = useState(defaultOrderDir);
  const [page,setPage] = useState(1);
  const [pages,setPages] = useState(1);
  const [resultsCount,setResultsCount] = useState(0);
  const [currentSearch,setCurrentSearch] = useState("");
  const [didMount,setDidMount] = useState(false);
  const searchRef = useRef(null);

  useEffect(() => {
    return () => {
      unSubRequest("table-search-"+name);
    }
  },[name]);

  useDebouncedEffect(() =>{
    if(load){
      performSearch({refresh:true});
    }
  },1,[refreshInc,load]);

  useDebouncedEffect(() => {
    if(load){
      performSearch({currentSearchOpt:currentSearch});
    }
  },600,[currentSearch,searchConditions,load]);

  const updateConditions = (i,i2,name,value,column,newCond) => {
    let cloneConds = clone(searchConditions);
    if(cloneConds.length <= 0 || newCond){
      if(!column){
        column = columns[0]
        for (let i = 0; i < columns.length; i++) {
          if(columns[i].default){ column = clone(columns[i]); break; }
        }
      }
      let cond = {
        value: "",
        action: "LIKE",
        column: column,
      }
      if(name === "value"){ cond.value = value;}
      if(cond.column.type && cond.column.type === "select"){ 
        cond.value = (column.values && column.values.length)?column.values[0]:"";
        cond.action = "="; 
      }

      if(i === -1 || cloneConds.length <= 0){
        cloneConds.push([cond]);
      }else{
        cloneConds[i].push(cond)
      }
    }else{
      let cond = cloneConds[i][i2];
      cond[name] = value;
      if(cond.column.type && cond.column.type === "select"){
        cond.action = "=";
      }
    }
    setSearchConditions(cloneConds)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setCurrentSearch("");
    performSearch({currentSearchOpt:""});
  }

  const performSearch = ({currentSearchOpt,pageOpt,leftItemOpt,orderByOpt,orderDirOpt,refresh}) => {

    // console.log("currentSearchOpt",currentSearchOpt);
    // console.log("pageOpt",pageOpt);
    // console.log("leftItemOpt",leftItemOpt);
    // console.log("orderByOpt",orderByOpt);
    // console.log("orderDirOpt",orderDirOpt);
    // console.log("refresh",refresh);

    let currentSearchRes = (currentSearchOpt)?currentSearchOpt:currentSearch;
    let pageRes = (pageOpt)?pageOpt:page;
    let leftItemRes = (leftItemOpt)?leftItemOpt:leftActiveItem;
    let orderByRes = (orderByOpt !== undefined)?orderByOpt:orderBy;
    let orderDirRes = (orderDirOpt !== undefined)?orderDirOpt:orderDir;

    // console.log("currentSearchRes",currentSearchRes);
    // console.log("pageRes",pageRes);
    // console.log("leftItemRes",leftItemRes);
    // console.log("orderByRes",orderByRes);
    // console.log("orderDirRes",orderDirRes);

    let columnData = [];
    for (let i = 0; i < columns.length; i++) {
      const column = columns[i];
      if(column.ignore) continue;

      columnData.push({
        database: column.database,
        table: column.table,
        value: column.value,
        type: column.type,
        as: column.as,
        join: column.join,
      })
    }
    
    let cleanConditions = [];
    for (let i = 0; i < searchConditions.length; i++) {
      const searchConditionGroup = searchConditions[i];
      const condGroup = [];
      for (let j = 0; j < searchConditionGroup.length; j++) {
        const searchCondition = searchConditionGroup[j];
        if(searchCondition.value !== ""){
          let clonedCondition = clone(searchCondition)
          condGroup.push(clonedCondition);
        }
      }
      if(condGroup.length) cleanConditions.push(condGroup);
    }

    if(leftItemRes && leftItemRes.length){
      if(cleanConditions.length){
        for (let i = 0; i < cleanConditions.length; i++) {
          const cleanCondition = cleanConditions[i];
          for (let j = 0; j < leftItemRes.length; j++) {
            const leftItemCondition = leftItemRes[j];
            cleanCondition.push(leftItemCondition);
          }
        }
      }else{
        cleanConditions.push(leftItemRes);
      }
    }

    for (let i = 0; i < cleanConditions.length; i++) {
      const condGroups = cleanConditions[i];
      for (let i = 0; i < condGroups.length; i++) {
        const cond = condGroups[i];
        if(typeof cond.column === 'object' && cond.column !== null){
          cond.table = cond.column.table;
          cond.column = cond.column.value;
        }
      }
    }

    let data = {
      columns: columnData,
      conditions: cleanConditions,
      database: (database)?database:null,
      joinOptions: joinOptions,
      orderBy: orderByRes,
      orderDir: orderDirRes,
      limit: limit,
      page: pageRes,
    }

    let newSearch = JSON.stringify(data);
    if(newSearch === currentSearchRes && !(refresh)){
      return false
    };

    setCurrentSearch(newSearch);
    setLoading(true);
    request("table-search"+name,searchEndpoint,"POST",data, {
      then: function(res){
        setResults(res.data.res.results);
        setPages(res.data.res.pages);
        setPage(res.data.res.page);
        setResultsCount(res.data.res.resultsCount);
      },
      catch: function(err){toast.error(err.message);},
      finally: function(){
        setLoading(false);
      }
    }) 
  }
  
  const uppdateResultState = (index,item,value) => {
    let resultsClone = clone(results);
    resultsClone[index][item] = value;
    setResults(resultsClone);
  }

  let advSearch = (onlyAdvancedSearch)?onlyAdvancedSearch:false;
  if(searchConditions.length >= 2){
    advSearch = true;
  }else if(searchConditions.length && (searchConditions[0].length >= 2 || searchConditions[0][0].action !== "LIKE")){
    advSearch = true;
  }

  searchRef.current = performSearch;
  useEffect(() =>{
    if(!didMount && load){
      setDidMount(true);
      searchRef.current({});
    }
  },[didMount,searchRef,load])

  return (
    <div className={"table-element"} >
      <div className={"left-block"+((sideItems.length)?"":" hide")}>
        {
          sideItems.map((v,i) => {
            let display = v.display?v.display:i+"";
            let activeClass = "";
            let active = false;
            let customClass = (v.custom)?"custom":"";
            if(leftActiveItemKey === i){
              activeClass = "active";
              active = true;
            }
            return (
              <div className={"left-block-item "+activeClass+" "+customClass} key={i} 
                onClick={() => {
                  if(loading) return false;
                  
                  let conditions = clone((v.conditions)?v.conditions:[]);
                  if(leftActiveItemKey === i && sideItemActiveClickFunc){
                    sideItemActiveClickFunc(v);
                  }else{
                    performSearch({leftItemOpt:conditions});
                  }
                  setLeftActiveItem(conditions);
                  setLeftActiveItemKey(i);
                }}>
                {(v.custom)?v.custom({value:v.value,active:active}):display}
              </div>
            )
          })
        }
      </div> 
      <div className="center-block">
        <div className="search-block">
          <form className={useSearchBar?"":"hide"} onSubmit={handleSubmit}>
            <div className={"search-conditions singular"+((advSearch)?" hide":"")}>
              <input placeholder="Search" type="text" 
                value={(searchConditions.length && searchConditions[0].length)?searchConditions[0][0].value:""} 
                onChange={(e) => {updateConditions(0,0,"value",e.target.value,columns[0])}}
                />
              
              <select
                value={(searchConditions.length && searchConditions[0].length)?searchConditions[0][0].column.value:columns[0].value} 
                onChange={(e) => {
                  let val = e.target.value;
                  let column = columns[0];
                  for (let i = 0; i < columns.length; i++) {
                    const columnH = clone(columns[i]);
                    if(val === columnH.value){
                      column = columnH;
                      break;
                    }
                  }
                  updateConditions(0,0,"column",column,column);
                }}
                >
                {columns.map((v,i) => {
                  if(v.ignore) return null;
                  if(!v.index) return null;
                  let display = StringUtil.prettifyCamelCase(v.value);
                  display = (v.display)?v.display:display;

                  return (
                    <option value={v.value} key={i}>
                      {display}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className={"search-conditions multi"+((advSearch)?"":" hide")} onClick={() => {setShow(true)}}>
              {searchConditions.map((v,i) => {
                return(
                  <div className="search-conditon-group" key={i}>
                    {
                      v.map((v2,i2) => {
                        return(
                          <div className="search-condition" key={i2}>
                            {v2.column.value} {v2.action} {v2.value}
                          </div>
                        )
                      })
                    }
                  </div>
                )
              })}
            </div>
            
            {disableAdvancedSearch?null:
              <Button parentClassName="advanced-search" status={"search-advanced"} type="button" onClick={() => {setShow(true)}}>
                Advanced Search
              </Button>
            }
            <Button parentClassName="search" type="submit" status={"search"} value={"Search"}/>
            {(newButtonOnClickFunc)?
            <Button parentClassName="add-button"  status={"add"} type="button" onClick={newButtonOnClickFunc}>
              {(newButtonText)?newButtonText:"Add"}
            </Button>
            :null}
            {(buttons)?
              buttons.map((v,i) => {
                return(
                  <Button 
                    key={i}
                    parentClassName={(v.parentClassName)?v.parentClassName:"add-button"}
                    status={v.status?v.status:"add"} 
                    type="button" 
                    onClick={v.onClick}>
                    {v.text}
                  </Button>
                )
              })
            
            :null}
          </form>
          <div className="results-count">
            {(resultsCount)?("Showing ")+(page*limit-limit+1)+"-"+((page*limit>resultsCount)?resultsCount:page*limit)+" of ":""}
            {(resultsCount)?(resultsCount+" "+((resultsCount !== 1)?"Results":"Result")):"No Results"}
          </div>
        </div>
        <div className="main-block">
          <div className="content-block">
            <div className={"loading-overlay "+(loading?"show":"hide")}>
              <Loading show={loading}/>
            </div>
            <table>
              <thead>
                <tr className="header">
                  {columns.map((v,i) => {
                    if(v.hide) return null;
                    let display = StringUtil.prettifyCamelCase(v.value);
                    display = (v.display)?v.display:display;

                    let orderClass = "";
                    if(orderBy && orderBy.value === v.value){
                      orderClass = " order-"+((orderDir)?orderDir.toLowerCase():"");
                    }

                    return (
                      <th className={"hlo"+((v.ignore || !v.index)?" ignore":"")+orderClass} key={i} onClick={() => {
                        if(v.ignore) return;
                        if(!v.index) return;
                        
                        let sameColumn = (orderBy !== null && orderBy.value === v.value);
                        let column = v;
                        let direction = null;
                        if(sameColumn){
                          if(orderDir === "ASC") direction = "DESC";
                        }else{
                          direction = "ASC";
                        }

                        if(direction === null) column = null;
                        setOrderBy(column);
                        setOrderDir(direction);
                        performSearch({orderByOpt: column, orderDirOpt: direction})
                      }}>
                        {display}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {results.map((v,i) => {
                  return(
                    <tr key={i}>
                      {columns.map((v2,i2) => {
                        if(v2.hide) return null;
                        let cv = v2.table+StringUtil.capitalize(v2.value);
                        return(
                          <td className={(rowOnClickFunc && !v2.ignoreRowClick)?"pointer":""} 
                            key={i2} onClick={() =>{
                            if(rowOnClickFunc && !v2.ignoreRowClick){
                              rowOnClickFunc(v);
                            }
                          }}>
                            {(v2.custom)?v2.custom({v,cv:cv,i,uppdateResultState,}):v[cv]}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {(!loading&&results.length<=0)?
            <div className="no-results">
              No Results
            </div>:null}
          </div>
          <div className="footer-block">
            <div className="pagination-container">
              <Pagination 
                page={page}
                pages={pages}
                setPage={(newPage) => {
                  setPage(newPage);
                  performSearch({pageOpt:newPage});
                }}
                />
            </div>
            <div className="buttons"></div>
          </div>
        </div>
      </div> 

      <Modal className="table-search-advanced-modal" show={show} handleClose={() => {setShow(false)}}>
        <div className="table-search-advanced-modal-main">
          <h3>{displayName} Advanced Search</h3>
          <div className="search-conditions">
            {searchConditions.map((v,i) => {
              
              return(
                <div className="search-condition-group" key={i}>
                  {
                    v.map((v2,i2) => {
                      let activeColumnIndex = 0;
                      let activeColumn = null;
                      for (let j = 0; j < columns.length; j++) {
                        const column = columns[j];
                        if(column.value === v2.column.value){
                          activeColumnIndex = j;
                          activeColumn = column;
                          break;
                        }
                      }

                      let useSearchActions = searchActions;
                      if(activeColumn && activeColumn.type && activeColumn.type === "date"){
                        useSearchActions = ["=",">","<",">=","<="];
                      }

                      return (
                        <div className="search-condition" key={i2}>
                          <select value={activeColumnIndex} onChange={(e) => {updateConditions(i,i2,"column",columns[e.target.value])}}>
                            {columns.map((v4,i4) => {
                              if(v4.ignore) return null;
                              if(!v4.index) return null;

                              let display = StringUtil.prettifyCamelCase(v4.value);
                              display = (v4.display)?v4.display:display;
                              return(
                                <option value={i4} key={i4}>
                                  {display}
                                </option>
                              )
                            })}
                          </select>
                          {(activeColumn && activeColumn.type && activeColumn.type === "select")?<div className="action-lock">=</div>:
                          <select value={v2.action} onChange={(e) => {updateConditions(i,i2,"action",e.target.value)}}>
                            {useSearchActions.map((v3,i3) => <option value={v3} key={i3}>{v3}</option>)}
                          </select>
                          }
                          
                          {(activeColumn && activeColumn.type && activeColumn.type === "select")?
                          <select className="value-select" value={v2.value} onChange={(e) => {updateConditions(i,i2,"value",e.target.value)}}>
                            {activeColumn.values.map((v5,i5) => <option value={v5} key={i5}>{v5}</option>)}
                          </select>
                          :(activeColumn && activeColumn.type && (activeColumn.type === "epochtime" || activeColumn.type === "date"))?
                          <TypeEpochInput time={v2.value} setTime={(val) => {
                            updateConditions(i,i2,"value",val)
                          }}/>
                          :<input type="text" value={v2.value} onChange={(e) => {updateConditions(i,i2,"value",e.target.value)}} />
                          }
                          
                          
                          <Button status={"delete"} onClick={() => {
                            let cloneConds = clone(searchConditions);
                            cloneConds[i].splice(i2,1);
                            if(!cloneConds[i].length) cloneConds.splice(i,1);
                            setSearchConditions(cloneConds);
                          }}>
                            Delete
                          </Button>
                        </div>
                      )
                    })
                  }
                  <div className="button-div">
                    <Button status={"add"} onClick={() => {
                      updateConditions(i,-1,"","",null,true);
                    }}>
                      And
                    </Button>
                    <Button status={"copy"} onClick={() => {
                      let cloneConds = clone(searchConditions);
                      let cloneCond = clone(cloneConds[i])
                      cloneConds.splice(i,0,cloneCond);
                      setSearchConditions(cloneConds);
                    }}>
                      Clone
                    </Button>
                    <div className={"or-div"+((i === searchConditions.length-1)?" hide":"")}>
                      <div className="line"></div>
                      <div className="text">OR</div>
                      <div className="line"></div>
                    </div>
                  </div>
                  
                </div>
              )
            })}          
          </div>

          <Button parentClassName="add-or-condition" status={"add"} onClick={() => {
            updateConditions(-1,-1,"","",null,true);
          }}>
            Add New Condition Group
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Table;