
import React, { useEffect, useState, useRef } from "react";
import "./cs-modal.scss";
import * as DateUtil from "../../../utils/date";
import * as StringUtil from "../../../utils/string";
import Modal from "../../common/modal/modal";
import Button from "../../elements/button/button";
import {request,unSubRequest} from "../../../utils/request";
import {toast} from 'react-toastify';
import Loading from "../../common/loading/loading";
import TextareaAutosize from 'react-textarea-autosize';
import {clone} from '../../../utils/clone';
import useUserState from '../../../store/user';


const CustomerSupport = ({show,handleClose,ticketId}) => {

  const [ticket,setTicket] = useState(null);
  const [ticketMessages,setTicketMessages] = useState([]);
  const [disable,setDisable] = useState(false);

  const [reply,setReply] = useState("");
  const [loadingT,setLoadingT] = useState(false);
  const [loadingS,setLoadingS] = useState(false);
  const [loadingD,setLoadingD] = useState(false);
  const {user} = useUserState();

  const divRef = useRef(null);

  const scrollToBottom = () => {
    divRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [ticketMessages]);

  const handleSubmit = (e) => {
    e.preventDefault();
    sendReply();
  }

  useEffect(() => {
    let newDisable = false;
    if(ticket && ticket.status === "closed"){
      newDisable = true;
    }
    setDisable(newDisable);
  },[ticket])

  useEffect(() => {
    fetchTicket(ticketId);
    return () => {
      setTicket(null);
      setTicketMessages([]);
      unSubRequest("reply-ticket");
      unSubRequest("change-ticket-status");
      unSubRequest("assign-ticket");
      unSubRequest("delete-ticket-message");
      unSubRequest("ticket");
    }
  },[ticketId]);
  
  const sendReply = () => {
    let data = {  text: reply, id: ticketId, side:"staff"}
    setLoadingS(true);
    request("reply-ticket","/reply-ticket","POST", data, {
      then: function(res){ 
        setReply("");
        fetchTicket(ticketId);
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoadingS(false);}
    });
  }

  const toggleStatus = (id,status) => {
    setLoadingT(true);
    request("change-ticket-status","/change-ticket-status","POST", {id,status}, {
      then: function(res){ 
        let cloneTicket = clone(ticket)
        cloneTicket.status = status;
        setTicket(cloneTicket);
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoadingT(false);}
    });
  }
  const assignToMe = (id,userId) => {
    setLoadingT(true);
    request("assign-ticket","/assign-ticket","POST", {id,staffUserId:userId}, {
      then: function(res){ 
        let cloneTicket = clone(ticket)
        cloneTicket.staffUserId = userId;
        setTicket(cloneTicket);
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoadingT(false);}
    });
  }

  const deleteMessage = (index,id) => {
    setLoadingD(true);
    request("delete-ticket-message","/ticket-message","DELETE", {id}, {
      then: function(res){ 
        let cloneMessages = clone(ticketMessages)
        cloneMessages.splice(index, 1);
        setTicketMessages(cloneMessages);
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoadingD(false);}
    });
  }

  const fetchTicket = (id) => {
    if(!id) return;

    setLoadingT(true);
    request("ticket","/ticket/"+id,"GET", {}, {
      then: function(res){ 
        setTicket(res.data.res.ticket);
        setTicketMessages(res.data.res.ticketMessages);
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoadingT(false);}
    });
  }

  return (
    <Modal className="cs-modal" show={show} handleClose={handleClose}>
      <div className="cs-modal-main">
        <div className="cs-header">
          <div className="buttons">
            <Button status={(loadingT)?"loading":""} onClick={() => {
              if(ticket){
                assignToMe(ticketId,user.id);
              }
            }}>Assign to Me</Button>
            <Button status={(loadingT)?"loading":""} type="button" onClick={() => {
              if(ticket){
                let newStatus = (ticket.status === "closed")?"open":"closed";
                toggleStatus(ticketId,newStatus);
              }
            }}>{(ticket && ticket.status === "closed")?"Reopen":"Mark Closed"}</Button>
          </div>
          {(ticket)?
            <div className="info">
              <div>Topic: {StringUtil.capitalize(ticket.type)}</div>
              <div>UserId: {ticket.userId}</div>
              <div>StaffUserId: {ticket.staffUserId}</div>
              <div>Status: {StringUtil.capitalize(ticket.status)}</div>
              <div>Needs Reply: {(ticket.needsReply)?"True":"False"}</div>
            </div>
          :null}
          
        </div>
        <div className="cs-content">
          <Loading show={loadingT}/>
          <div className="ticket-messages">
            {ticketMessages.map((v,i) => {
              return (
                <div className={"ticket-message "+((v.staff)?"staff":"not-staff") }key={i}>
                  {(v.staff)?
                  <div className="header">
                    <div>Staff</div>
                    <div>UserId: {v.userId}</div>
                    {/* <Button status={(loadingT)?"loading":"edit"}>Edit</Button> */}
                    <Button status={(loadingT || loadingD)?"loading":"delete"} onClick={() => {
                      deleteMessage(i,v.id)
                    }}>Delete</Button>
                  </div>
                  :<div className="header">
                    <div>UserAuthId: {v.userAuthId}</div>
                    <div>UserIp: {v.userIp}</div>
                    <div>UserId: {v.userId}</div>
                  </div>}
                  <div className="text-container">
                    <TextareaAutosize disabled={true} className="text" defaultValue={v.text} />
                  </div>
                  <div className="date">
                    <div className="date">{DateUtil.dateTimeSinceWithTime(v.createdDate)}</div>
                  </div>
                </div>
              )
            })}
            <div className="last" ref={divRef}></div>
          </div>
          
        </div>
        <div className="cs-footer">
          <form onSubmit={handleSubmit}>
            <TextareaAutosize placeholder="Reply" disabled={loadingT || loadingS || disable} value={reply} onChange={(e) => {
              setReply(e.target.value)
            }} />
            <Button disabled={disable} status={(loadingT || loadingS)?"loading":""}  type="submit" value="Reply" />
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default CustomerSupport;
