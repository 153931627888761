import React, { useState, } from "react";
import "./coins.scss";
import * as DateUtil from "../../../utils/date";

import Table from '../../elements/table/table';
import Modal from "../../common/modal/modal";

const Coins = () => {

  const [showValue,setShowValue] = useState(null);
  const [showModal,setShowModal] = useState(false);
  
  const sideItems = [];

  const columns = [
    {table: "coins",value: "id",hide: true, index:true,},
    {table: "coins",value: "coin", index:true,},
    {table: "coins",value: "label", index:true,},
    {table: "coins",value: "trading",},
    {table: "coins",value: "hidden",},
    {table: "coins",value: "comingSoon",},
    {table: "coins",value: "coinOrder",},
    {table: "coins",value: "defaultTradingPair",},
    {table: "coins",value: "createdDate", index:true,
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
    {table: "coins",value: "listedDate", index:true,
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,   
    }, 
    {table: "coins",value: "updatedDate", index:true,
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
  ]
  const joinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"outputs",rightTable:"addresses",leftColumn:"address",rightColumn:"address",},
  ]

  return (
    <div className="coins-comp">
      <div className="coins-comp-header">
        <h3>Coins</h3>
      </div>
      <Table 
        database={"Trade"}
        displayName="Coins"
        name="CoinsTable"
        sideItems={sideItems}
        sideItemActiveClickFunc={(item) => {
          // console.log("item",item);
        }}
        joinOptions={joinOptions}
        rowOnClickFunc={(value) => {
          setShowValue(value);
          setShowModal(true);
        }}
        columns={columns}
        limit={250}
        />

      <Modal className="coins-modal" show={showModal} handleClose={() => {setShowModal(false)}}>
        <div className="coins-modal-main">
          <pre>
          {JSON.stringify(showValue, null, 2)}
          </pre>
        </div>
      </Modal>

    </div>
  );
};

export default Coins;
