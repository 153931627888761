import React, { useState, } from "react";
import "./deposits.scss";
import * as DateUtil from "../../../utils/date";
import * as NumberUtil from "../../../utils/number";

import Table from '../../elements/table/table';
import Modal from "../../common/modal/modal";
import {CoinEnum} from "../../../enums/coins";

const Deposits = () => {

  const [showValue,setShowValue] = useState(null);
  const [showModal,setShowModal] = useState(false);

  const sideItems = [{display: "All", conditions:[]},];
  for (let key in CoinEnum) {
    const coin = CoinEnum[key];
    sideItems.push({
      display: coin.Coin, 
      conditions:[{
        table:"deposits",
        column:"coin", 
        action: "=",
        value: coin.Coin,
      }]
    });
  }

  const columns = [
    {table: "deposits",value: "id",hide: true, index:true,},
    {table: "deposits",value: "userId", index:true,},
    {table: "deposits",value: "address", index:true,},
    {table: "deposits",value: "coin", index:true,},
    {table: "deposits",value: "valueSats", display: "Value",
      custom: ({v,cv}) => <div>{NumberUtil.satsToVal(v[cv])}</div>,  
    },
    {table: "deposits",value: "outputId", index:true,},
    {table: "deposits",value: "txId",hide:true, index:true,},
    {table: "deposits",value: "createdDate", index:true,
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
    {table: "deposits",value: "transferedToTrade", index:true,
      custom: ({v,cv}) => <div>{(v[cv])?"True":"False"}</div>,  
    }, 
    {table: "deposits",value: "transferedToTradeDate", 
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
  ]
  const joinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"outputs",rightTable:"addresses",leftColumn:"address",rightColumn:"address",},
  ]

  return (
    <div className="deposits-comp">
      <div className="deposits-comp-header">
        <h3>Deposits</h3>
      </div>
      <Table 
        database={"Node"}
        displayName="Deposits"
        name="DepositsTable"
        sideItems={sideItems}
        sideItemActiveClickFunc={(item) => {
          // console.log("item",item);
        }}
        joinOptions={joinOptions}
        rowOnClickFunc={(value) => {
          setShowValue(value);
          setShowModal(true);
        }}
        columns={columns}
        limit={250}
        />

      <Modal className="deposits-modal" show={showModal} handleClose={() => {setShowModal(false)}}>
        <div className="deposits-modal-main">
          <pre>
          {JSON.stringify(showValue, null, 2)}
          </pre>
        </div>
      </Modal>

    </div>
  );
};

export default Deposits;
