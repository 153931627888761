import React, { useState, } from "react";
import "./users.scss";
import Button from "../../elements/button/button";
import * as DateUtil from "../../../utils/date";
import { ReactComponent as PersonSvg } from "../../../css/imgs/icon-person.svg";

import useUserState from "../../../store/user";
import Table from '../../elements/table/table';
import AccountStatusEnum from "../../../enums/account-status";
import UserModal from "./user-modal";

const Users = () => {

  const { user } = useUserState();
  
  const [showUserModal, setShowUserModal] = useState(false);
  const [showUser, setShowUser] = useState({});
  const [refreshInc,setRefreshInc] = useState(0);

  const refresh = (refreshInc) => {
    setRefreshInc(refreshInc+1);
  }

  const sideItems = [{display:"Any",conditions:[]}];
  for (let key in AccountStatusEnum) {
    let accountStatus = AccountStatusEnum[key];
    if(AccountStatusEnum.Any === accountStatus) continue;
    if(user.status < accountStatus || accountStatus === AccountStatusEnum) continue;
    sideItems.push({
      display: key,
      conditions:[{
        action: "=",
        column:"status", 
        table:"users",
        value: accountStatus,
      }]
    });
  }

  const columns = [
    {table: "users",value: "id",hide: true, index:true, },
    {table: "users",value: "username",default: true, index:true, 
      custom: ({v,cv}) => {
        return(
        <div className={"username-column"}>
          {/* {v.userBioDataProfilePictureId !== null ? (
            <img src={Config.Common.CloudFrontFull+v['userBioDataProfilePictureId']+".png"} alt={v.username}/>
          ):( */}
            <div className="svg-container"><PersonSvg /></div>
          {/* )} */}
          {v[cv]}
        </div>
      )},
    },
    {table: "users",value: "email", index:true, },
    {table: "users",value: "status", hide:true, index:true, },
    {table: "users",value: "createdDate", index:true, 
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
    {table: "users",value: "lastActive", 
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
  ]
  const joinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"users",rightTable:"userAuthTokens",leftColumn:"id",rightColumn:"userId",},
  ]

  return (
    <div className="users-comp">
      <div className="users-comp-header">
        <h3>Users</h3>
        <Button
          status="add"
          className="button"
          onClick={() => {
            setShowUser(null);
            setShowUserModal(true);
          }}>
          New User
        </Button>
      </div>
      <Table 
        displayName="Users"
        name="UsersTable"
        sideItems={sideItems}
        sideItemActiveClickFunc={(item) => {
          // console.log("item",item);
        }}
        joinOptions={joinOptions}
        rowOnClickFunc={(value) => {
          setShowUser(value);
          setShowUserModal(true);
        }}
        refreshInc={refreshInc}
        columns={columns}
        limit={250}
      />

      <UserModal
        user={user}
        show={showUserModal}
        handleClose={() => {
          setShowUser(null);
          setShowUserModal(false);
        }}
        refresh={() => {refresh(refreshInc);}}
        viewUser={showUser}
      />
    </div>
  );
};

export default Users;
