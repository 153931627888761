import { useState, useEffect, } from 'reactn';
import useUserState from '../store/user';
import {toast} from 'react-toastify';
import Config from '../config/config';
import {clone} from './clone';

import SocketStateEnum from '../enums/socket-state';

const useSocket = () => {

  const {user} = useUserState();
  const [socket, setSocket] = useState(null); 
  const [socketHeaders, setSocketHeaders] = useState(null);
  const [socketMessages, setSocketMessages] = useState(null);

  const openSocket = (socketHeaders,user) => {
    // console.log("openSocket");
    let newSocket = new WebSocket(Config.Common.ApiBaseSocket);
    newSocket.addEventListener('open', function (event) {
      let cloneSocketHeaders = clone(socketHeaders);
      cloneSocketHeaders.user = user
      // console.log("websocket open")
      newSocket.send(JSON.stringify(cloneSocketHeaders));
      setSocket(newSocket);
    });

    newSocket.addEventListener('message', function (event) {
      let data = JSON.parse(event.data);
      if(data.err.code){
        toast.error(data.err.message);
        socket.close();
      }
      setSocketMessages(data.res);
    });
    newSocket.addEventListener('close', function (event) {
      // console.log("websocket closed");
      setSocket(null);
    });
  }

  const keepSocketAlive = (socket,setSocket,) => {
    let timenow = new Date().getTime();
    let json = {
      event: "keep-alive",
      timenow: timenow,
    };
    if(socket.readyState !== SocketStateEnum.Open){
      setSocket(null);
      return;
    }
    socket.send(JSON.stringify(json));
  }

  useEffect(() => {
    let interval = null;
    if(socket === null && socketHeaders !== null){
      openSocket(socketHeaders,user,);   
      interval = setInterval(() => {
        setSocket(null);
        openSocket(socketHeaders,user);      
      },2550)
    }
    return () => {
      if(socketHeaders === null){
        setSocket(null);
      }
      if(interval !== null){
        clearInterval(interval);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[socket,socketHeaders,user,])

  useEffect(() => {
    let interval = null;
    if(socket !== null){
      keepSocketAlive(socket,setSocket);
      interval = setInterval(() => {
        keepSocketAlive(socket,setSocket);
      },1000);
    }
    return () => {
      if(interval !== null){
        clearInterval(interval);
      }
    }
  },[socket,setSocket,]);

  return {
    socketMessages,
    setSocketHeaders,
  };
}
export default useSocket;
