import React,{useState, useEffect} from 'react';
import './verify-email.scss';
import CenterDiv from '../../../common/center-div/center-div';
import {request,unSubRequest} from '../../../../utils/request';
import useUserState from '../../../../store/user';
import Button from '../../../elements/button/button';
import Loading from '../../../common/loading/loading';
import {toast} from 'react-toastify';

// Mostly the same as reset-password - separate to keep me sane
const VerifyEmail = ({tokenArg}) => {

  const {setUser} = useUserState();
  const [email,setEmail] = useState("");
  const [buttonStatus,setButtonStatus] = useState("");
  const [loading,setLoading] = useState(false);
  const [verifyingToken,setVerifyingToken] = useState(false);
  const [verifiedToken,setVerifiedToken] = useState(false);
  const [errorMessage,setErrorMessage] = useState("");
  const [emailSent,setEmailSent] = useState(false);
  const [token] = useState(tokenArg);

  useEffect(() => {
    return () => {
      unSubRequest("verify-email");
      unSubRequest("verify-email-request");
    }
  },[]);

  useEffect(() => {
    if(token !== undefined && token !== null && token !== ""){
      const endpoint = "/verify-email";
      const body = { token:token };
      if(localStorage.getItem("device")) body.deviceToken = localStorage.device;

      setVerifyingToken(true);
      request("verify-email",endpoint,"POST", body, {
        then: function(res){
          let results = res.data.res;
          console.log("results",results);
          if(results.user && results.auth){
            let userHold = results.user;
            userHold.auth = results.auth;
            if(results.newDeviceToken) localStorage.setItem("device",results.newDeviceToken);
            setVerifiedToken(true);
            setUser(userHold);
          }else{
            toast.error("Login Error, expecting auth token but was null");
          }
        },
        catch: function(err){
          setErrorMessage(err.message);
        },
        finally: function(){
          setVerifyingToken(false);
        }
      });
    }
  },[token,setUser]);

  useEffect(() => {
    setButtonStatus((loading)?"loading":"");
  },[loading]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if(email === ""){
      return false;
    }

    const data = {
      email: email
    };

    setLoading(true);
    setErrorMessage("");
    setEmailSent(false);
    request("verify-email-request","/verify-email-request","POST", data, {
      then: function(res){
        setEmailSent(true);
      },
      catch: function(err){
        setErrorMessage(err.message);
      },
      finally: function(){
        setLoading(false);
      }
    });

  }

  if(verifyingToken || verifiedToken){
    return(
      <CenterDiv>
        <div className="verify-email">
          <h1>Verifying Token </h1>
          {(verifiedToken)?
            <div className="success">Success, Email verified. Logging you in!</div>:
            <Loading show={true} size={"48px"}/>
          }
        </div>
      </CenterDiv>
    )
  }

  return (
    <CenterDiv>
      <div className="verify-email">
        <h1>Verify Email </h1>
        {(emailSent)?<ul className="success">
          <li>Email sent, please check your email. </li>
        </ul>:""}
        {(errorMessage !== "")?<ul className="errors">
          <li>{errorMessage}</li>
        </ul>:""}
        <p>Before you can sign in you'll need to verify your email. You can type in your email below to receive another verification link.</p>
        <form onSubmit={handleSubmit}>
          <label>Email</label>
          <input disabled={loading} type="email" placeholder="Email" value={email} onChange={(e) => {setEmail(e.target.value)}} />
          <Button disabled={loading} type="submit" status={buttonStatus} value="Send Verification Code" />
        </form>
      </div>
    </CenterDiv>
  );
};

export default VerifyEmail;