import { clone } from '../utils/clone';

import { navi } from './views/navi';
import { faq } from './views/faq';

/**
 * Converts our message structure to only contain one locale's text. This is required by our i18n library
 *
 * @param {Object} messages A key/value object containing several localisation texts
 * @param {String} locale The key of the locale text we want in the output
 */
 const convertToSingularLocale = (obj, locale ) => {
  let item = clone(obj);

  for (let key in item) {
    if (key === locale) {
      // this key is a locale, return it
      return item[key];
    } else if (typeof item[key] === 'object') {
      item[key] = convertToSingularLocale(item[key], locale)
    }
  }

  return item;
}

const translations = {
  general: {
    languageAbbreviation: {
      de: "de",
      es: "es",
      en: "en",
      ja: "ja",
      fr: "fr",
      zh: "zh",
    },
  },
  faq: faq,
  navi: navi,
}

// export const german = convertToSingularLocale(translations, 'de')
// export const spanish = convertToSingularLocale(translations, 'es')
export const english = convertToSingularLocale(translations, 'en')
export const japanese = convertToSingularLocale(translations, 'ja')
// export const french = convertToSingularLocale(translations, 'fr')
// export const chinese = convertToSingularLocale(translations, 'zh')

export const availableLanguages = [
  { name: "English", nativeName:"English", code: "en" },
  { name: "Japanese", nativeName:"日本語", code: "ja" },
]