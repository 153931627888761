export const navi = {
  help: {
    en: 'Help',
    ja: 'ヘルプ',
  },
  login: {
    en: "Login",
    ja: "ログイン",
  },
  createAccount: {
    en: "Create Account",
    ja: "アカウントを作成",
  },
  myAccount: {
    en: "My Account",
    ja: "マイアカウント",
  },
  logout: {
    en: "Logout",
    ja: "ログアウト",
  },
  language: {
    en: "English",
    ja: "日本語"
  },
  changeLanguage:{
    en: "Change Language",
    ja: "言語の変更"
  }
}