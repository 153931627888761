import React from 'react';

const History = ({viewUser,}) => {

  return (
    <div className="history-comp">
      history-comp
    </div>
  );

};

export default History;