
const AccountStatusEnum = {
  Any: 0,
  EmailUnverified: 1,
  Banned: 2,
  Disabled: 3,
  Basic: 4,
  Premium: 5,
  Staff: 6,
  Admin: 7,
  SuperAdmin: 8,
}

export const AccountStatusIdEnum = {
  0:"Any",
  1:"EmailUnverified",
  2:"Banned",
  3:"Disabled",
  4:"Basic",
  5:"Premium",
  6:"Staff",
  7:"Admin",
  8:"SuperAdmin",
}

export default AccountStatusEnum;