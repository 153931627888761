import React, { useState, } from "react";
import "./credits.scss";
import * as DateUtil from "../../../utils/date";
import * as NumberUtil from "../../../utils/number";

import Table from '../../elements/table/table';
import Modal from "../../common/modal/modal";
import {CoinEnum} from "../../../enums/coins";

const Credits = () => {

  const [showValue,setShowValue] = useState(null);
  const [showModal,setShowModal] = useState(false);
  
  const sideItems = [
    {display: "All", conditions: []},
  ];
  for (let key in CoinEnum) {
    const coin = CoinEnum[key];
    sideItems.push({
      display: coin.Coin, 
      conditions:[{
        table:"credits",
        column: "coin",
        action:"=",
        value: coin.Coin,
      }]
    });
  }

  const columns = [
    {table: "credits",value: "id",hide: true, index:true, },
    {table: "credits",value: "ownerId", index:true},
    {table: "credits",value: "coin", index:true},
    {table: "credits",value: "availableSats", display:"Available",
      custom: ({v,cv}) => <div>{NumberUtil.satsToVal(v[cv])}</div>,  
    },
    {table: "credits",value: "frozenSats", display:"Frozen",
      custom: ({v,cv}) => <div>{NumberUtil.satsToVal(v[cv])}</div>,  
    },
    {table: "credits",value: "createdDate", index:true,
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
    {table: "credits",value: "updatedDate", index:true,
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
  ]
  const joinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"outputs",rightTable:"addresses",leftColumn:"address",rightColumn:"address",},
  ]

  return (
    <div className="credits-comp">
      <div className="credits-comp-header">
        <h3>Credits</h3>
      </div>
      <Table 
        database={"Trade"}
        displayName="Credits"
        name="CreditsTable"
        sideItems={sideItems}
        sideItemActiveClickFunc={(item) => {
          // console.log("item",item);
        }}
        joinOptions={joinOptions}
        rowOnClickFunc={(value) => {
          setShowValue(value);
          setShowModal(true);
        }}
        columns={columns}
        limit={250}
        />

      <Modal className="credits-modal" show={showModal} handleClose={() => {setShowModal(false)}}>
        <div className="credits-modal-main">
          <pre>
          {JSON.stringify(showValue, null, 2)}
          </pre>
        </div>
      </Modal>

    </div>
  );
};

export default Credits;
