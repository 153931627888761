export const faq = [
  {
    question: {
      en: "question 1",
      ja: "question 1 ja",
    },
    answer: {
      en: "answer 1",
      ja: "answer 1 ja",
    },
  },{
    question: {
      en: "question 2",
      ja: "question 2 ja",
    },
    answer: {
      en: "answer 2",
      ja: "answer 2 ja",
    },
  },{
    question: {
      en: "question 3",
      ja: "question 3 ja",
    },
    answer: {
      en: "answer 3",
      ja: "answer 3 ja",
    },
  },
]