import React from 'react';
import './test.scss';

// import useSocket from '../../../utils/use-socket';
import {request } from '../../../utils/request';
import { toast } from 'react-toastify';

const Test = () => {

  // const {socketMessages,setSocketHeaders} = useSocket();

  // useEffect(() => {
  //   console.log("Test Mount");
  //   const socketHeaders = {
  //     event: "subscribe",
  //     channels: {
  //       orderBook: "BTC-USDT",
  //       recentTrades: "BTC-USDT",
  //       openOrders: "BTC-USDT",
  //       liveChart: "BTC-USDT",
  //       creditsCoinPair: "BTC-USDT",
  //     },
  //   }
  //   setSocketHeaders(socketHeaders);
  //   return () => {
  //     setSocketHeaders(null);
  //   }
  // },[setSocketHeaders]);

  // useEffect(() => {
  //   console.log("Test socketMessages",socketMessages);
  // },[socketMessages])

  const test = () => {
    let endpoint = "/test";
    request("test",endpoint,"GET", {}, {
      then: function(res){
        console.log("res",res);
        toast.info("done"); 
      },
      catch: function(err){ toast.error(err.message); },
      finally: function(){}
    });
  }

  return (
    <div className="test">
      <h3>Test Component</h3>

      <button onClick={() => {test()}}>test</button>
    </div>
  );
};

export default Test;