import React,{useState,useEffect} from "react";
import "./developer.scss";
import * as DateUtil from "../../../utils/date";
import Table from '../../elements/table/table';
import Carousel from '../../common/carousel/carousel';
import CarouselHeader from '../../common/carousel/carousel-header';
import usePersistState from "../../../utils/use-persist-state";
import {DatabaseEnum} from '../../../enums/database';
import ReactTextareaAutosize from 'react-textarea-autosize';

const tables = [
  {display:"Database Version",table:"z_database_version", columns:[
    {table: "zDatabaseVersion",value: "id", index:true, },
    {table: "zDatabaseVersion",value: "version", },
    {table: "zDatabaseVersion",value: "token", },
    {table: "zDatabaseVersion",value: "lastUpdated", index:true, defaultOrder:"DESC",
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
  ]},
  {display:"Logs",table:"z_log",columns:[
    {table: "zLog",value: "id", index:true, },
    {table: "zLog",value: "header", },
    {table: "zLog",value: "logObj", 
      custom: ({v,cv}) => <ReactTextareaAutosize defaultValue={v[cv]} readOnly></ReactTextareaAutosize>,  
  },
    {table: "zLog",value: "createdDate", index:true, defaultOrder:"DESC",
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
  ]},
  {display:"Error Logs",table:"z_error_log",columns:[
    {table: "zErrorLog",value: "id", index:true, },
    {table: "zErrorLog",value: "header", },
    {table: "zErrorLog",value: "logObj", 
      custom: ({v,cv}) => <ReactTextareaAutosize defaultValue={v[cv]} readOnly></ReactTextareaAutosize>,  
    },
    {table: "zErrorLog",value: "createdDate", index:true, defaultOrder:"DESC",
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
  ]},
  {display:"Warning Logs",table:"z_warning_log",columns:[
    {table: "zWarningLog",value: "id", index:true, },
    {table: "zWarningLog",value: "header", },
    {table: "zWarningLog",value: "logObj", 
      custom: ({v,cv}) => <ReactTextareaAutosize defaultValue={v[cv]} readOnly></ReactTextareaAutosize>,  
    },
    {table: "zWarningLog",value: "createdDate", index:true, defaultOrder:"DESC",
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
  ]},
  {display:"Database Error Logs",table:"z_database_error_log",columns:[
    {table: "zDatabaseErrorLog",value: "id", index:true, },
    {table: "zDatabaseErrorLog",value: "errorMsg", 
      custom: ({v,cv}) => <ReactTextareaAutosize defaultValue={v[cv]} readOnly></ReactTextareaAutosize>,  
    },
    {table: "zDatabaseErrorLog",value: "sqlText", 
      custom: ({v,cv}) => <ReactTextareaAutosize defaultValue={v[cv]} readOnly></ReactTextareaAutosize>,  
    },
    {table: "zDatabaseErrorLog",value: "varText", 
      custom: ({v,cv}) => <ReactTextareaAutosize defaultValue={v[cv]} readOnly></ReactTextareaAutosize>,  
    },
    {table: "zDatabaseErrorLog",value: "createdDate", index:true, defaultOrder:"DESC",
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
  ]},
  {display:"Database Table Update",table:"z_database_table_update",columns:[
    {table: "zDatabaseTableUpdate",value: "id", index:true, },
    {table: "zDatabaseTableUpdate",value: "table", },
    {table: "zDatabaseTableUpdate",value: "createdDate", index:true, defaultOrder:"DESC",
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
    {table: "zDatabaseTableUpdate",value: "updatedDate", 
      custom: ({v,cv}) => <div>{DateUtil.dateTimeSince(v[cv])}</div>,  
    },
  ]},
];

const Developer = () => {

  const [slide,setSlide] = usePersistState(1,"developerSlide","number");
  const [activeDatabase,setActiveDatabase] = usePersistState(DatabaseEnum.Main,"developerDatabase");
  const [activeTable,setActiveTable] = usePersistState(tables[0].table,"developerTable");
  const [show,setShow] = useState(true);

  useEffect(() => {
    setShow(true);
  },[show])

  let columns = tables[0].columns
  for (let i = 0; i < tables.length; i++) {
    const table = tables[i];
    if(table.table === activeTable){
      columns = table.columns
      break;
    }
  }

  return (
    <div className="developer-comp">
      <div className="totals-comp-header">
        <h3>Developer</h3>
      </div>
      <CarouselHeader slideTo={slide}>
        <div onClick={() => { setSlide(1);}}>Dash</div>
        <div onClick={() => { setSlide(2);}}>Database</div>
      </CarouselHeader>
      <Carousel slideTo={slide}>
        <div>Developer dash</div>
        <div>
          <div>
            <div className="databases">
              {Object.keys(DatabaseEnum).map((key, i) => {
                let v = DatabaseEnum[key];
                return(
                  <div className={(v === activeDatabase)?"active":""} key={i} onClick={() => {
                    setActiveDatabase(v);
                    setShow(false);
                  }}>
                    {v}
                  </div>
                )
              })}
            </div>
            <div className="tables">
              {tables.map((v,i) => {
                return(
                  <div className={(v.table === activeTable)?"active":""} key={i} onClick={() => {
                    setActiveTable(v.table);
                    setShow(false);
                  }}>
                    {v.display}
                  </div>
                )
              })}
            </div>
          </div>
          {show?<Table 
            database={activeDatabase}
            displayName={"developer"+activeDatabase+activeTable}
            name={"developer"+activeDatabase+activeTable+"Table"}
            sideItems={[]}
            sideItemActiveClickFunc={(item) => {}}
            joinOptions={[]}
            rowOnClickFunc={(value) => {}}
            columns={columns}
            limit={50}
            />:null}
        </div>
      </Carousel>
      

    </div>
  );
};

export default Developer;
