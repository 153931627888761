import * as NumberUtil from './number';
import {UsdCoins} from '../enums/coins'

export const estimateCoinValue = (coin,amountSats,tickerCoinPairs) => {

  let usdValueCents = 0;
  let btcValueSats = 0;

  if(coin === "BTC"){
    let coinPair = "BTC-USDT";
    if(coinPair in tickerCoinPairs){
      usdValueCents = tickerCoinPairs[coinPair] * NumberUtil.satsToVal(amountSats);
      usdValueCents = NumberUtil.valFloatPointFix(usdValueCents);
      usdValueCents = NumberUtil.valToCents(usdValueCents);
    }
    btcValueSats = amountSats;
  }else if(UsdCoins.indexOf(coin) !== -1){
    usdValueCents = NumberUtil.valToCents(NumberUtil.satsToVal(amountSats));
    let coinPair = "BTC-USDT";
    if(coinPair in tickerCoinPairs){
      btcValueSats = NumberUtil.valFloatPointFix(NumberUtil.satsToVal(amountSats) / tickerCoinPairs[coinPair])
      btcValueSats = NumberUtil.valToSats(btcValueSats);
    }
  }else{
    let btcCoinPair = coin+"-BTC";
    if(btcCoinPair in tickerCoinPairs){
      btcValueSats = NumberUtil.valFloatPointFix(NumberUtil.satsToVal(amountSats) * tickerCoinPairs[btcCoinPair])
      btcValueSats = NumberUtil.valToSats(btcValueSats);
    }

    let usdCoinPair = "BTC-USDT";
    if(usdCoinPair in tickerCoinPairs && btcValueSats !== 0){
      usdValueCents = tickerCoinPairs[usdCoinPair] * NumberUtil.satsToVal(btcValueSats);
      usdValueCents = NumberUtil.valFloatPointFix(usdValueCents);
      usdValueCents = NumberUtil.valToCents(usdValueCents);
    }
  }

  return {usdValueCents,btcValueSats};
}
