import React,{useState, useEffect} from 'react';
import './sign-up.scss';

import Button from '../../../elements/button/button';
import PasswordInput from '../../../elements/password-input/password-input';
import {request,unSubRequest} from '../../../../utils/request'
import { Link } from 'react-router-dom';
import Config from '../../../../config/config';
import Checkbox from '../../../elements/checkbox/checkbox';
import {toast} from 'react-toastify';
import Recaptcha from 'react-google-recaptcha';
import * as NumberUtil from '../../../../utils/number';

const SignUp = ({successCallback,checkUpdateFunc}) => {

  const [recaptchaKey,setRecaptchaKey] = useState(1);
  const [recaptchaValue,setRecaptchaValue] = useState("");
  const [username,setUsername] = useState("");
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");
  const [check,setCheck] = useState(false);
  const [loading,setLoading] = useState(false);
  const [buttonStatus,setButtonStatus] = useState("");
  const [success,setSuccess] = useState(false);

  useEffect(() => {
    return () => {
      unSubRequest("sign-up");
    }
  },[]);

  useEffect(() => {
    setButtonStatus((loading)?"loading":"");
  },[loading]);

  useEffect(() => {
    if(checkUpdateFunc) checkUpdateFunc(check);
  },[check,checkUpdateFunc])

  const handleSubmit = (e) => {
    e.preventDefault();
    if(!check){
      toast.error("Please confirm that you've reviewed the terms of use. ")
      return;
    }

    if(username !== "" && email !== "" && password !== ""){

      const data = {
        username,
        email,
        password,
        recaptchaValue,
      };
      if(localStorage.getItem("device")){
        data.deviceToken = localStorage.device;
      }

      setLoading(true);
      request("sign-up","/user","POST", data, {
        then: function(res){
          let results = res.data.res;
          console.log("results",results);
          if(results.newDeviceToken) localStorage.setItem("device",results.newDeviceToken);
          setSuccess(true);
        },
        catch: function(err){ toast.error(err.message)},
        finally: function(){
          let randInt = NumberUtil.getRandomInt(2,99999999);
          setRecaptchaKey(randInt);
          setRecaptchaValue("");
          setLoading(false);
        }
      });
    }
  }

  if(success){
    if(successCallback !== undefined){
      successCallback();
    }
    return(
      <div className="sign-up">
        <div className="success">
          <p>Success! Registered your account. Please check your email. </p>
          <p>Check your junk/spam mail if it's not in your inbox. </p>
          <h6>If you didn't receive an email. <Link to="/verify-email">Resend Verification Email</Link></h6>
          <h6>If you think you may have an account with us already. <Link to="/reset-password">Reset Password</Link></h6>
        </div>
      </div>
    )
  }

  return (
    <div className="sign-up">
      <form onSubmit={handleSubmit}>
        <label>Username</label>
        <input disabled={loading} type="text" placeholder="Username" defaultValue={username} onChange={(e) => {setUsername(e.target.value)}}/>
        <label>Email</label>
        <input disabled={loading} type="email" placeholder="Email" defaultValue={email} onChange={(e) => {setEmail(e.target.value)}}/>
        <label>Password</label>
        <PasswordInput disabled={loading} placeholder="Password" defaultValue={password} onChange={(e) => {setPassword(e.target.value)}}/>
        <label className="legal">
          <Checkbox value={check} setValue={setCheck} />
          <span>I have read and agree to {Config.Common.AppName}’s <a href="/terms" target="_blank" rel="noopener noreferrer">Terms of Use</a></span>
        </label>
        <Recaptcha key={recaptchaKey}
            className="recaptcha" sitekey={Config.Keys.RecaptchaPubKey} 
            onChange={(value) => {setRecaptchaValue(value)}}
            onExpired={() => {setRecaptchaValue("")}}/>
        <Button disabled={loading} type="submit" status={buttonStatus} value="Create Account"/>
      </form>
    </div>
  );
};

export default SignUp;