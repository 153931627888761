import React,{useState,useEffect,} from 'react';
import './totp.scss';

import Button from '../../../elements/button/button';
import CenterDiv from '../../../common/center-div/center-div';
import { Link, Redirect } from 'react-router-dom';
import * as BrowserUtil from '../../../../utils/browser';
import {toast} from 'react-toastify';
import {request,unSubRequest} from '../../../../utils/request';
import useUserState from '../../../../store/user';

const Totp = () => {

  const {setUser} = useUserState();
  const [loading,setLoading] = useState(false);
  const token = BrowserUtil.getQueryVariable("token");
  const [value,setValue] = useState("");
  const [redirect,setRedirect] = useState(null);

  useEffect(() => {
    return () => {
      unSubRequest("login-totp")
    }
  },[])

  const handleSubmit = (e) => {
    e.preventDefault();
    totpLoginRequest();
  }

  const totpLoginRequest = () => {
    if(value === "") return;

    const data = {
      totpLoginToken: token,
      totpToken: value,
    };
    if(localStorage.getItem("device")){
      data.deviceToken = localStorage.device;
    }

    setLoading(true);
    request("login-totp","/login-totp","POST", data, {
      then: function(res){
        let results = res.data.res;
        console.log("results",results);
        if(results.deviceConfirmationRequired){
          let newRedirect = "/new-device?token="+results.deviceLoginToken;;
          setRedirect(newRedirect);
        }else if(results.auth && results.user){
          toast.success("Success! Logging you in 😎");
          let holdUser = results.user;
          holdUser.auth = results.auth;
          setUser(holdUser);
        }else{
          toast.error("Error: Auth Tokens not returned. Please contact us if this happens.");
        }
      },
      catch: function(err){
        let newRedirect = (err.redirect)?"/login":redirect;
        setRedirect(newRedirect);
        toast.error(err.message);
      },
      finally: function(){setLoading(false);}
    });
  }

  if(redirect){
    return <Redirect to={redirect}/>
  }

  return (
    <CenterDiv>
      <div className="totp-component">
        <h1>Security verification</h1>
        <span className="text">To secure your account, please complete the following verification.</span>
        <form onSubmit={handleSubmit}>
          <label>Authenticator Code</label>
          <input type="text" value={value} onChange={(e) => {setValue(e.target.value)}}/>
          <span className="hint">Enter the 6 digit code from your TOTP Authenticator App. </span>
          <Link to="/totp-reset">Security verification unavailable?</Link>
          <Button status={(loading)?"loading":""} type="submit"/>
        </form>
      </div>
    </CenterDiv>
    
  );

};

export default Totp;