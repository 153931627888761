const CoinEnum = {
  DAWI:{  Coin:"DAWI",  Decimal: 4, Name:"Dawicoin"},
  BTC:{   Coin:"BTC",   Decimal: 6, Name:"Bitcoin"},
  ETH:{   Coin:"ETH",   Decimal: 4, Name:"Ethereum"},
  LTC:{   Coin:"LTC",   Decimal: 4, Name:"Litecoin"},
  BCH:{   Coin:"BCH",   Decimal: 4, Name:"Bitcoin Cash"},
  DASH:{  Coin:"DASH",  Decimal: 4, Name:"Dashcoin"},
  DOGE:{  Coin:"DOGE",  Decimal: 2, Name:"Dogecoin"},
  USD:{   Coin:"USD",   Decimal: 2, Name:"US Dollar", Fiat:true},
  USDT:{  Coin:"USDT",  Decimal: 2, Name:"Tether"},
  USDC:{  Coin:"USDC",  Decimal: 2, Name:"USD Coin"},
  ADA:{   Coin:"ADA",   Decimal: 4, Name:"Cardano"},
}

const StableCoins = [
  CoinEnum.USDC.Coin,CoinEnum.USDT.Coin,
]
const UsdCoins = [
  CoinEnum.USD.Coin,CoinEnum.USDT.Coin,CoinEnum.USDC.Coin,CoinEnum.USDT.Coin,
]

module.exports = {
  CoinEnum,
  StableCoins,
  UsdCoins
};
