import React,{useState,useEffect} from 'react';
import {toast} from 'react-toastify';

import Button from "../../../elements/button/button";
import { request, unSubRequest } from "../../../../utils/request";

const Admin = ({userId,refresh,handleClose}) => {

  const [loading,setLoading] = useState(false);

  useEffect(() => {
    return () => {
      unSubRequest("user-admin-delete-"+userId);
    }
  },[userId])

  const deleteUser = (userId) => {
    if (userId === null) {
      toast.error("This is a new user. ");
      return ;
    }

    let data = {
      userId: userId,
    };

    setLoading(true);
    request("user-admin-delete-"+userId, "/user-admin-delete", "DELETE", data, {
      then: function (res) {
        if(refresh) refresh();
        if(handleClose) handleClose();
      },
      catch: function (err) {
        toast.error(err.message);
      },
      finally: function () {
        setLoading(false);
      },
    });
  };

  return (
    <div className="danger-zone">
      <h3>The Danger Zone</h3>

      <label>Deletes the user's account, bio-info, profile details & guardians</label>
      <Button
        status={loading ? "loading" : "delete"}
        onClick={() => {
          if (userId === null) {
            toast.error("This is a new user. ");
            return ;
          }

          let foo = window.confirm("Are you sure you want to delete this user?");
          if (foo) {
            deleteUser(userId);
          }
        }}
      >
        Delete User
      </Button>
    </div>
  );

};

export default Admin;