import React, { useState } from 'react';
import * as DateUtil from '../../../utils/date';
import './type-epoch-input.scss';
import {ReactComponent as SwapSvg} from '../../../css/imgs/icon-swap-vert.svg';


const TypeEpochInput = ({time,setTime,defaultInput,}) => {

  const [activeInputToggle,setActiveInputToggle] = useState((defaultInput && defaultInput === "epochtime")?"epochtime":"date")

  let epochTimenow = new Date().getTime();
  if(!isNaN(parseInt(time))){
    epochTimenow = new Date(parseInt(time)).getTime();
  }
  const startTime = DateUtil.epochToDatetimeLocal(epochTimenow,true);

  return (
    <div className="type-epoch-input">
      <div className="input-div">
        <input className={(activeInputToggle === "date")?"active":"sub"} type="datetime-local" value={startTime} onChange={(e) => {
          setTime(DateUtil.datetimeToEpoch(e.target.value,true))
        }}/>
        <input className={(activeInputToggle === "epochtime")?"active":"sub"} type="text" value={time} onChange={(e) => {
          setTime(e.target.value)
        }}/>
      </div>
      <div className="toggle-div" onClick={() => {setActiveInputToggle((activeInputToggle === "date")?"epochtime":"date")}}>
        <SwapSvg />
      </div>
    </div>
  )
}

export default TypeEpochInput;