import React from 'react';
import './coin-pairs.scss';

const CoinPairs = () => {

  return (
    <div className="coin-pairs-comp">
      <h1>coin-pairs-comp</h1>
    </div>
  );

};

export default CoinPairs;